import React, { useState } from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import NorthPointFachada from '../../images/NorthPointFachada.jpg'
import picarrasAerea from '../../images/picarrasAerea.jpeg'

export default function NorthPointBeach() {
    const [saberMais, setSaberMais] = useState(false);
    function handleClick() {
        if (saberMais) {
            setSaberMais(false)
        } else {
            setSaberMais(true)
        }
    }
    return (<Layout>
        <SEO
            keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
            title="Bivver"
        />
        <h2 className='my-16 italic text-center'>Empreendimento North Point</h2>
        <h3 className='text-center'>Tenha seu paraíso no litoral! Tenha seu apartamento no North Point!</h3>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-12 my-12 self-center'>
            <img className="lg:w-3/4 lg:ml-8" src={NorthPointFachada} />
            <p className='font-semibold italic'>Balneário Piçarras é o paraíso no litoral norte catarinense, com suas águas limpas, sua beleza natural e seu alto índice de desenvolvimento econômico torna-se uma ótima cidade para morar e investir!
<br />
                <br />
                O North Point Beach é um empreendimento de altíssimo padrão, qualidade e solidez, trazendo em um só lugar: lazer, conforto e segurança.
<br />
                <br />
                Com arquitetura moderna e arrojada, a Bivver traz novamente o que há de melhor em tecnologia de construção juntamente com ótimos acabamentos. São somente 16 apartamentos, absolutamente exclusivos!
 <br />
                <br />

                Com mais de 1000 m² de áreas de lazer e bem-estar ! Todos os apartamento tem vista para o mar, este é o melhor empreendimento de alto padrão que você vai conhecer!
<br />
                <br />
                Faça parte deste seleto grupo! Linha Bivver Exclusivity</p>
        </div>
        <h2 className='text-center mb-8'>Características</h2>
        <div className='bivverBlack grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-48 self-center'>
            <div>
                <div><b>Área de lazer</b></div>
                <div className='my-2'>Piscina com Borda Infinita</div>
                <div className='my-2'>Piscina Infantil Aquecida</div>
                <div className='my-2'>Quadra Poliesportiva</div>
                <div className='my-2'>Cabine para Delivery</div>
                <div className='my-2'>Forno de Pizza a Lenha</div>
                <div className='my-2'>Clube do Whisky</div>
                <div className='my-2'>Sala de Jogos</div>
                <div className='my-2'>Poker Room</div>
                <div className='my-2'>Brinquedoteca</div>
                <div className='my-2'>Playground</div>
                <div className='my-2'>Academia</div>
                {saberMais ?
                    (<>
                        <a onClick={handleClick} className='my-2'>Mostrar menos...</a>

                        <div className='my-2'>Praça</div>
                        <div className='my-2'>Deck Molhado</div>
                        <div className='my-2'>Spa</div>
                        <div className='my-2'>Redário</div>
                        <div className='my-2'>Sauda</div>
                        <div className='my-2'>Deck Externo</div>
                        <div className='my-2'>Fireplace</div>
                        <div className='my-2'>Jacuzzi</div>
                        <div className='my-2'>Bivver Pub</div>
                        <div className='my-2'>Salão de Festas 01</div>
                        <div className='my-2'>Salão de Festas 02</div>
                        <div className='my-2'>Cinema</div>
                        <div className='my-2'>Bivver Bike Community</div>
                        <div className='my-2'>Pet Place</div>
                    </>
                    ) :
                    <a onClick={handleClick} className='my-2'>Mostrar mais...</a>
                }
            </div>
            <div>
                <div><b>Área em comum</b></div>
                <div className='my-2'>Hall de Entrada Decorado</div>
                <div className='my-2'>1 Apartamento por andar</div>
                <div className='my-2'>1 Elevador para Banhistas</div>
                <div className='my-2'>1 Elevador de Serviço</div>
                <div className='my-2'>Cabine para Delivery</div>
                <div className='my-2'>Entrada para Banhistas</div>
            </div>
            <div>
                <div><b>Apartamentos</b></div>
                <div className='my-2'>Sala de Jantar</div>
                <div className='my-2'>Sala de Estar</div>
                <div className='my-2'>Ampla Cozinha</div>
                <div className='my-2'>Ampla área de serviço e despensa</div>
                <div className='my-2'>4 Suítes com Sacada</div>
                <div className='my-2'>Hall e Lavabo Social</div>
                <div className='my-2'>Churrasqueira e Sacada Independente</div>
                <div className='my-2'>Casa de maquinas</div>
            </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-12 my-24 self-center'>
            <div>
                <h2 className='mb-8'>Por que morar em Piçarras?</h2>
                <p>Com uma localização geográfica privilegiada,
                ficando aproximadamento 20 quilômetros do aeroporto de Navegantes e ligada por vias pavimentadas a BR-101,
                o que garante acesso rápido a outros polos de diversão e passeio, como o parque temático de Beto Carrero World, em Penha,
                a famosa Oktoberfest em Blumenau e ótimas compras em Brusque.
                    <br />
                    <br />
                    O Balneário Piçarras abrange belas praias que possuem bandeira
                    azul (certificado mundial de balneabilidade), tranquilidade e ótimcas opções de entretenimento em família. Com uma excelente
                    infraestrutura, a cidade está preparada para recepcionar da melhor forma possível turistas de todos os cantos do mundo.
                    Pousadas, bares, casas noturnas, mercados e restaurantes que oferecem o famoso rodízio de frutos do mar, são apenas uma pequena
                    amostra do que Piçarras tem a oferecer.
                    </p>
            </div>
            <img src={picarrasAerea} />
        </div>
    </Layout>)
}